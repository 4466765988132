import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  otpScreenRoot: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    height: "100%",
    color: "#FFF",
  },
  otpInputRoot: {
    "& input": {
      background: "transparent",
      outline: "none",
      border: "none",
      borderBottom: "2px solid #FFFFFF",
      // width: "100%!important",
      fontSize: "42px",
      color: "#FFF",
      padding: '11px 0px'
    },
    width: 64,
    marginTop: 100,
  },
  inputRoot: {},
});
