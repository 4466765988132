import React, { useEffect } from "react";
import ShopiLogo from "../../../assets/ShopiTV-logo.png";
import background from "../../../assets/bg-image.png";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "app/components/Page";
import { Button, Typography } from "@mui/material";
import PageContent from "app/components/PageContent";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import { useTranslation } from "react-i18next";

type Props = {};

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 30,
    fontWeight: 800,
    textAlign: "center",
    lineHeight: "35px",
    color: "#FAA61A",
  },
  primaryContainer: {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    padding: "12px 0",
  },
}));

const ThankYou = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.resetModule());
  }, []);

  return (
    <Page>
      {/* <PageContent> */}
      {/* <Box sx={{ height: "96px", backgroundColor: "#333333" }}></Box> */}
      <Box className={styles.primaryContainer}>
        <div></div>
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <img src={ShopiLogo} style={{ height: "48px" }} alt="app-logo" />
          <Typography className={styles.heading} mt={1.5}>
            <span>{t("auth.thankyou.thankyou")}!</span>
            <br />
            <span>{t("auth.thankyou.welcomeText")}</span>
          </Typography>
        </Box>
        <Button
          sx={{
            width: "85%",
            py: "12px",
            fontSize: "24px",
            fontWeight: 800,
            borderRadius: "10px",
            mt: 5,
          }}
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "https://shopi-tv.com/")}
        >
          {t("auth.continue")}
        </Button>
      </Box>
      {/* </PageContent> */}
    </Page>
  );
};

export default ThankYou;
