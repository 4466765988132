/**
 * Gets the repositories of the user from Github
 */

import { call, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { getDefaultHeaders, getFormattedDateTime } from "utils/helpers";
import { request } from "utils/request";

// import { request } from 'utils/request';
import {
  selectCreateAccountForm,
  selectLoginForm,
  selectOtp,
  selectToken,
  selectType,
} from "./selectors";
import { actions } from "./slice";
import { LoginErrorType } from "./types";

/**
 * Github repos request/response handler
 */

function* verifyOTPRequest(action) {
  try {
    const token = yield select(selectToken);
    const otp = yield select(selectOtp);
    const form = yield select(selectCreateAccountForm);
    const type = yield select(selectType);
    if (type === "LOGIN") {
      const token = yield select(selectToken);
      const { phone } = yield select(selectLoginForm);
      const loginData = {
        phone: phone.value.replace("-", ""),
        code: otp.value,
      };
      const options = {
        method: "POST",
        headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
        body: JSON.stringify(loginData),
      };
      const response = yield request(`${API_URL}/web-auth/code-login`, options);
      console.log(response, "login response");
      if (
        response &&
        response.message === "MESSAGE.OTP.TOO_MANY_INVALID_ATTEMPTS"
      ) {
        debugger;
        yield put(
          actions.setError({
            message: `You are blocked after ${response.loginAttempts} invalid attempts, please try after ${response.duration}.`,
            attempts: response.loginAttempts,
            duration: response.duration,
          })
        );
        console.log(action);
        yield call(() => action.payload.callback("/invalid"));
        return;
      }
      if (response && response.message === "MESSAGE.USER.LOGIN_SUCCESS") {
        yield put(actions.setLoading(false));
        yield call(action.payload.callback);
        return;
      } else {
        yield put(action.payload.callback("/invalid"));
        yield put(actions.setLoading(false));
        return;
      }
    } else {
      const options = {
        method: "POST",
        body: JSON.stringify({
          phone: form.phone.value.replace("-", ""),
          code: otp.value,
          dob: getFormattedDateTime(
            form.dateOfBirth?.value.toISOString(),
            "yyyy-MM-dd"
          ),
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          // code: "1234",
          email: form.email.value,
          gender: form.gender.value.toUpperCase(),
          isAcceptedPrivacyPolicy: form.acceptPrivacy.value,
          isAcceptedTermsOfUse: form.acceptTerms.value,
        }),
        headers: {
          ...getDefaultHeaders(),
          Authorization: `Bearer ${token}`,
        },
      };
      const response = yield request(`${API_URL}/web-auth/update`, options);
      yield put(actions.setLoading(false));
      if (
        response &&
        response.message === "MESSAGE.OTP.TOO_MANY_INVALID_ATTEMPTS"
      ) {
        yield put(
          actions.setError(
            `You are blocked after ${response.loginAttempts} invalid attempts, please try after ${response.duration}.`
          )
        );
        yield call(() => action.payload.callback("/invalid"));
        return;
      }
      if (response && response.message === "MESSAGE.USER.UPDATE_SUCCESS") {
        yield call(action.payload.callback);
        return;
      }
      if (response && response.message === "MESSAGE.OTP.INVALID") {
        yield put(actions.setError("Invalid OTP"));
        yield put(action.payload.callback("/invalid"));
        return;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* createAccountRequest(action) {
  const form = yield select(selectCreateAccountForm);

  try {
    const form = yield select(selectCreateAccountForm);
    const token = yield select(selectToken);
    const userData = {
      phone: form.phone.value.replace("-", ""),
      dob: getFormattedDateTime(form.dateOfBirth?.value.toISOString(), "yyyy-MM-dd"),
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      // code: "1234",
      email: form.email.value,
      gender: form.gender.value.toUpperCase(),
      isAcceptedPrivacyPolicy: form.acceptPrivacy.value,
      isAcceptedTermsOfUse: form.acceptTerms.value,
    };

    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify(userData),
    };
    const updateResponse = yield request(`${API_URL}/web-auth/update`, options);
    console.log({ updateResponse });
    if (
      updateResponse &&
      updateResponse.message === "MESSAGE.OTP.TOO_MANY_INVALID_ATTEMPTS"
    ) {
      yield put(
        actions.setError(
          `You are blocked after ${updateResponse.loginAttempts} invalid attempts, please try after ${updateResponse.duration}.`
        )
      );
      yield call(() => action.payload.callback("/invalid"));
      return;
    }
    if (updateResponse && updateResponse.message === "MESSAGE.OTP.SENT") {
      if (action.payload && action.payload.callBack) {
        yield call(action.payload.callBack);
      }
    }
  } catch (e) {
    yield put(actions.setLoading(false))
    console.log("error", e);
  }
}

export function* loginRequest(action) {
  try {
    const token = yield select(selectToken);
    const { phone, code } = yield select(selectLoginForm);
    const loginData = {
      phone: phone.value.replace("-", ""),
      code: code.value,
    };
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify(loginData),
    };
    const response = yield request(`${API_URL}/web-auth/code-login`, options);
    yield put(actions.setLoading(false));
    console.log(response, "login response");
    if (
      response &&
      response.message === "MESSAGE.OTP.TOO_MANY_INVALID_ATTEMPTS"
    ) {
      yield put(
        actions.setError(
          `You are blocked after ${response.loginAttempts} invalid attempts, please try after ${response.duration}.`
        )
      );
      yield call(() => action.payload.callback("/invalid"));
      return;
    }
    if (response && response.message === "MESSAGE.OTP.SENT") {
      if (action.payload.callback) {
        yield call(action.payload.callback);
      }
    } else {
      yield put(actions.loginError("Something went wrong"));
    }
  } catch (e) {
    console.log("error", e);
    yield put(actions.loginError(e));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.createAccountRequest.type, createAccountRequest);
  yield takeLatest(actions.verifyOtpRequest.type, verifyOTPRequest);
  yield takeLatest(actions.login.type, loginRequest);
}
