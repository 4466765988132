import { Button, FormHelperText, TextField, Typography } from "@mui/material";
import { makeStyles, StyledProps } from "@mui/styles";
import { Box, textAlign, Theme } from "@mui/system";
import TextFieldWithLabel from "app/components/FormFieldWithLabel";
import Page from "app/components/Page";
import PageContent from "app/components/PageContent";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import OtpVerification from "../OTPVerification";
import { selectLoading, selectLoginForm } from "../selectors";
import { actions } from "../slice";
import { validator } from "./validator";

type Props = {};

const Login = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { macAddress } = useParams();
  const form = useSelector(selectLoginForm);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOtpVerify, setIsOtpVerify] = useState<boolean>(false);
  const loading = useSelector(selectLoading);
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validator(form);
    console.log({ errors });
    if (errors.length) {
      dispatch(actions.setLoginFormErrors({ key: "", errors }));
      return;
    } else {
      dispatch(
        actions.login({
          callback: (url = `/${macAddress}/otp`) => {
            // setIsOtpVerify(true);
            navigate(url);
            // dispatch(actions.setLoading(false));
          },
        })
      );
    }
  };

  const formatValue = (value: string) => {
    const result =
      value && value.length > 3 && !value.includes("-")
        ? value.substring(0, 3) + "-" + value.substring(3)
        : value;
    console.log(result);
    return result;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setLoginForm({ name, value }));
  };
  if (isOtpVerify) {
    return <OtpVerification />;
  }

  const isDisabled = !form.phone.value;
  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {t("login.subHedding")}
            </Typography>
            <Typography
              onClick={() => {
                navigate("/create-account");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {t("login.signUp")}
            </Typography>
          </div>
        }
        heading={<>{t("login.button_text")}</>}
      />
      <PageContent pt="182px">
        <Box px={"12px"}>
          <form
            style={{
              // backgroundColor: "#999",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "40%",
              height: "70vh",
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                // backgroundColor: "#911",
                flexDirection: "column",
                marginTop: 20,
                // height: "20vh",
                // justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "#F2F2F2",
                  textAlign: "center",
                }}
              >
                {t("auth.createAccount.title")}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#F2F2F2",
                  textAlign: "center",
                  maxWidth: 270,
                  margin: "auto",
                }}
              >
                {t("auth.createAccount.subtitle")}
              </Typography>
              <TextFieldWithLabel
                label={t("auth.createAccount.form.phone")}
                labelStyle
                field={
                  <TextField
                    className={styles.inputField}
                    variant="standard"
                    name="phone"
                    // type="number"
                    inputMode="tel"
                    onChange={handleInputChange}
                    value={formatValue(form.phone.value)}
                    error={!!form.phone.error}
                    // placeholder="dinidnind"
                    sx={{
                      input: {
                        textAlign: "center",
                      },
                    }}
                  />
                }
                helperText={
                  form?.phone?.error ? t(`${form?.phone?.error}`) : ""
                }
              />
            </div>
            <div
              style={{
                // textAlign: "center",
                flex: 1,
                // backgroundColor: "#911",
                height: "30vh",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Typography sx={{ color: "#8F8E8E", fontSize: 14 }}>
                {t("login.otpMsg")}
              </Typography>
              <Button
                disabled={isDisabled}
                type="submit"
                variant="contained"
                sx={{
                  width: "100%",
                  fontSize: "24px",
                  fontWeight: 800,
                  padding: "12px 0",
                  borderRadius: "10px",
                  mt: 1.5,
                  // flex: 1,
                  maxHeight: "56px",
                  maxWidth: "286px",
                  margin: "30px 0px",
                }}
                color="primary"
              >
                {loading ? t("auth.loading") : t("auth.continue")}
              </Button>
            </div>
          </form>
        </Box>
      </PageContent>
    </Page>
  );
};

export default Login;

export const useStyles = makeStyles({
  inputField: {
    width: 240,
    maxHeight: 45,
    margin: "auto",
    // backgroundColor: "#911",
    borderBottom: "2px solid #fff",
    borderRadius: 2,
    textAlign: "center",
    // "& .MuiInputBase-input-MuiInput-input": {
    //   border: "none",
    // },
  },
  // bottom: {
  //   backgroundColor: "#fff",
  //   padding: 3,
  // minHeight: 45,
  //   width: 240,
  //   borderRadius: "10px",
  //   margin: "auto",
  // },
});
