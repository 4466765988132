import React, { useEffect } from "react";
import ShopiLogo from "../../../assets/ShopiTV-logo.png";
import background from "../../../assets/bg-image.png";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "app/components/Page";
import { Button, Typography } from "@mui/material";
import PageContent from "app/components/PageContent";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectAuth,
  selectError,
  selectErrorMessage,
  selectInvalidAttempts,
} from "../selectors";

type Props = {};

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 30,
    fontWeight: 800,
    textAlign: "center",
    lineHeight: "35px",
    color: "#FAA61A",
  },
  primaryContainer: {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "12px 0",
  },
}));

const InvalidCode = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const error = useSelector(selectErrorMessage);
  const auth = useSelector(selectAuth);
  const invalidAttempt = useSelector(selectInvalidAttempts);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(error, auth);
  }, [error, auth]);

  const dynamicError = (
    <>
      {t("auth.invalidAttempt1")} {invalidAttempt.attempts}
      {t("auth.invalidAttempt2")} {invalidAttempt.duration}
    </>
  );

  return (
    <Page>
      {/* <PageContent> */}
      {/* <Box sx={{ height: "96px", backgroundColor: "#333333" }}></Box> */}
      <Box className={styles.primaryContainer}>
        <div></div>
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <img src={ShopiLogo} style={{ height: "48px" }} alt="app-logo" />
          <Typography className={styles.heading} mt={1.5}>
            {/* {dynamicError} */}
            {error ? (
              dynamicError
            ) : (
              <>
                <span>{t("auth.invalid.invalidText1")}</span>
                <br />
                <span>{t("auth.invalid.invalidText2")}</span>
              </>
            )}
          </Typography>
        </Box>
        <Button
          sx={{
            width: "85%",
            py: "12px",
            fontSize: "24px",
            fontWeight: 800,
            borderRadius: "10px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(actions.setError(""));
            navigate(-1);
          }}
        >
          {t("auth.tryAgain")}
        </Button>
      </Box>
      {/* </PageContent> */}
    </Page>
  );
};

export default InvalidCode;
