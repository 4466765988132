import { Box, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageContent from "app/components/PageContent";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectError,
  selectInvalidAttempts,
  selectOtp,
  selectType,
} from "../selectors";
import { actions } from "../slice";
import { useStyles } from "./styles";
import TimerCount from "./components/TimerCount";
import { useTranslation } from "react-i18next";

type Props = {};

const OtpVerification = (props: Props) => {
  const dispatch = useDispatch();
  const [timerEnded, setTimerEnded] = useState(false);
  const otp = useSelector(selectOtp);
  const { t, i18n } = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const styles = useStyles();
  const error = useSelector(selectError);
  const typeLogin = useSelector(selectType);

  const navigate = useNavigate();
  const invalidAttempt = useSelector(selectInvalidAttempts);
  const dynamicError = `
      ${t("auth.invalidAttempt1")} ${invalidAttempt.attempts}
      ${t("auth.invalidAttempt2")} ${invalidAttempt.duration}`;

  const onSuccess = (url = "/thankyou") => {
    dispatch(actions.setOtp(""));
    navigate(url);
  };
  const handleChange = (otp) => {
    dispatch(actions.setOtp(otp));
    if (otp.length === 4) {
      dispatch(
        actions.verifyOtpRequest({
          callback: (url = "/thankyou") => {
            dispatch(actions.setOtp(""));
            navigate(url);
          },
        })
      );
    }
  };

  const resendOtp = () => {
    setClicked(true);
    if (typeLogin === "LOGIN") {
      dispatch(actions.login(""));
    } else {
      dispatch(actions.createAccountRequest(""));
    }
  };
  console.log({ clicked });
  useEffect(
    useCallback(() => {
      setTimeout(() => {
        setClicked(false);
      }, 1000);
    }, [])
  );

  return (
    <Page>
      <PageImageHeader
        heading={<>{t("verification.code")}</>}
        subHeading={<>{t("verification.subText")}</>}
      />
      <PageContent className={styles.otpScreenRoot} pt="182px" px="12px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OtpInput
            containerStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: i18n.language === "he" ? "row-reverse" : "row",
            }}
            value={otp.value}
            className={styles.otpInputRoot}
            onChange={handleChange}
            numInputs={4}
            isDisabled={timerEnded}
            isInputNum
          />
          {error && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "#F00",
                mt: 1,
                textAlign: "center",
              }}
            >
              {dynamicError}
            </Typography>
          )}
        </Box>
        {timerEnded && (
          <Typography
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#911",
              marginTop: "20px",
            }}
          >
            {t("verification.sessionExpired")}
          </Typography>
        )}
        <Box
          mt={3}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Typography sx={{ fontSize: "12px", mr: 1 }}>
            {t("verification.timeSpent")}
          </Typography>
          <TimerCount reset={clicked} setTimerEnded={setTimerEnded} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          mt={3}
        >
          <Typography sx={{ fontSize: "14px", lineHeight: "16px" }}>
            {t("verification.recieveCode")}
          </Typography>
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: "14px",
              lineHeight: "16px",
              mt: 1,
              cursor: "pointer",
              color: clicked ? "#faa619" : "#fff",
            }}
            onClick={resendOtp}
          >
            {t("verification.resendCode")}
          </Typography>
        </Box>
      </PageContent>
    </Page>
  );
};

export default OtpVerification;
