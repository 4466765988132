import React from "react";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";

import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, IconButton, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import { addDays } from "date-fns";
import { he, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const DatePicker = (
  { value, open, onOpen, onClose, onChange, label, name, error = "" },
  props
) => {
  const direction = useSelector(selectDirection);
  const handleOpen = () => {
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const todayDate = new Date();
  const minDate = todayDate.getFullYear() - 8;
  const maxDate = addDays(new Date(), -1);
  const setDate = new Date(new Date().setFullYear(minDate));
  const { i18n, t } = useTranslation();

  const handleDateChange = (date) => {
    if (date.getFullYear() > setDate.getFullYear()) {
      console.log("invalid date input");
      error = "invalid date input";
    }
    onChange({ target: { name, value: date } });
  };

  return (
    <>
      <LocalizationProvider
        locale={i18n.language === "he" ? he : enUS}
        dateAdapter={AdapterDateFns}
      >
        <div dir={direction} style={{ display: "flex" }}>
          <MobileDatePicker
            DialogProps={{
              dir: direction,
            }}
            open={open}
            onOpen={handleOpen}
            okText={t("auth.ok")}
            onClose={handleClose}
            label={t("auth.select")}
            cancelText={t("auth.cancel")}
            value={value}
            onChange={handleDateChange}
            maxDate={maxDate}
            inputFormat={"dd/MM/yyyy"}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                // inputProps={{
                //   endadornment: () =>
                //     // <InputAdornment position="end">
                //     //   <IconButton edge="end" onClick={handleOpen}>
                //     //     <EventIcon color="primary" />
                //     //   </IconButton>
                //     // </InputAdornment>
                //     0,
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                helperText={error}
                // sx={{ display: 'none' }}
              />
            )}
          />
        </div>
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
