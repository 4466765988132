import Page from "app/components/Page";
import PageContent from "app/components/PageContent";
import PageImageHeader from "app/components/PageImageHeader";
import FormFieldWithLabel from "app/components/FormFieldWithLabel";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Select } from "app/components/Select";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectCreateAccountForm, selectLoading } from "../selectors";
import DatePicker from "app/components/DatePicker";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import { valdiator } from "./validator";
import { FormError } from "app/containers/types";
import ErrorMessage from "app/components/ErrorMessage";
import { useTranslation } from "react-i18next";
type Props = {};

const CreateAccount = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { macAddress } = useParams();
  const token = new URL(window.location.href).searchParams.get("authToken");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const styles = useStyles();
  const navigate = useNavigate();

  const onSuccess = (url = `/${macAddress}/otp`) => {
    navigate(url);
  };

  const handleSubmit = () => {
    const errors: Array<FormError> = valdiator(form);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors({ key: "form", errors }));
      return false;
    }
    dispatch(actions.createAccountRequest({ callBack: onSuccess }));
  };
  const form = useSelector(selectCreateAccountForm);
  const loading = useSelector(selectLoading);

  const handleFormFieldChange = (e) => {
    const { name, type, checked } = e.target;
    const value = type === "checkbox" ? checked : e.target.value;
    dispatch(actions.setForm({ name, value }));
  };
  // const formatValue = form.phone.value
  //   .toString()
  //   .replace(/(\d{3})(\d+)/, "$1-$2");
  // const formattedValue = form.phone.value
  //   .toString()
  //   .replace(/(\d{3})(\d+)/, "$1-$2");
  // console.log({ formattedValue, formatValue });

  const continueDisabled =
    !form.firstName.value ||
    !form.lastName.value ||
    !form.dateOfBirth.value ||
    !form.email.value ||
    !form.phone.value ||
    // !formattedValue ||
    !form.gender.value ||
    !form.acceptTerms.value ||
    !form.acceptPrivacy.value;

  const formatValue = (value: string) => {
    const result =
      value && value.length > 3 && !value.includes("-")
        ? value.substring(0, 3) + "-" + value.substring(3)
        : value;
    console.log(result);
    return result;
  };

  const gender = [
    { name: "MALE", value: t("auth.Gender.male") },
    { name: "FEMALE", value: t("auth.Gender.female") },
  ];

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {t("login.haveAccount")}
            </Typography>
            <Typography
              onClick={() => {
                navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {t("login.login")}
            </Typography>
          </div>
        }
        heading={
          <>
            {t("auth.createAccount.heading1")}
            <br />
            {t("auth.createAccount.heading2")}
          </>
        }
        // subHeading={
        //   <span>
        //     {t("auth.createAccount.subheading1")}{" "}
        //     <Link
        //       to={`/${macAddress}/auth`}
        //       className={styles.checkboxLabelHighlight}
        //     >
        //       {t("auth.createAccount.subheading2")}
        //     </Link>
        //   </span>
        // }
      />
      <PageContent pt={"182px"} px={1.4}>
        <FormFieldWithLabel
          label={t("auth.createAccount.form.firstName") + "*"}
          name="firstName"
          value={form.firstName.value}
          error={!!form.firstName.error}
          helperText={form.firstName.error}
          required
          fullWidth
          onChange={handleFormFieldChange}
          mb={1}
        />
        <FormFieldWithLabel
          label={t("auth.createAccount.form.lastName") + "*"}
          name="lastName"
          value={form.lastName.value}
          error={!!form.lastName.error}
          helperText={form.lastName.error}
          required
          onChange={handleFormFieldChange}
          fullWidth
          mb={1}
        />
        <FormFieldWithLabel
          label={t("auth.createAccount.form.dob") + "*"}
          value={form.dateOfBirth.value}
          required
          fullWidth
          field={
            <DatePicker
              label={t("auth.createAccount.form.dob") + "*"}
              name="dateOfBirth"
              onChange={handleFormFieldChange}
              onClose={handleClose}
              onOpen={handleOpen}
              error={
                form?.dateOfBirth?.error ? t(`${form?.dateOfBirth?.error}`) : ""
              }
              open={open}
              value={form.dateOfBirth.value}
            />
          }
          mb={1}
        />
        <FormFieldWithLabel
          label={t("auth.createAccount.form.email") + "*"}
          required
          name="email"
          value={form.email.value}
          onChange={handleFormFieldChange}
          error={!!form.email.error}
          helperText={form?.email?.error ? t(`${form?.email?.error}`) : ""}
          fullWidth
          mb={1}
        />
        <FormFieldWithLabel
          label={t("auth.createAccount.form.phone") + "*"}
          name="phone"
          inputMode="tel"
          required
          fullWidth
          onChange={handleFormFieldChange}
          value={formatValue(form.phone.value)}
          error={!!form.phone.error}
          helperText={form?.phone?.error ? t(`${form?.phone?.error}`) : ""}
          mb={1}
        />
        <FormFieldWithLabel
          label={t("auth.createAccount.form.gender") + "*"}
          name="gender"
          field={
            <Select
              value={form.gender.value}
              error={form.gender.error}
              name="gender"
              placeholder={t("auth.Gender.title")}
              onChange={handleFormFieldChange}
              // defaultLabel="Select Gender"
              menuItems={gender.map((item) => (
                <MenuItem
                  dir={i18n.dir(i18n.language)}
                  value={item.name}
                  key={item.name}
                >
                  {item.value}
                </MenuItem>
              ))}
              required
              fullWidth
            />
          }
          mb={1}
        />
        <FormControlLabel
          sx={{ mb: 0 }}
          control={
            <Checkbox
              checked={form.acceptPrivacy.value}
              name="acceptPrivacy"
              value={form.acceptPrivacy.value}
              onChange={handleFormFieldChange}
            />
          }
          label={
            <Typography className={styles.checkboxLabel}>
              {t("auth.createAccount.form.privacyText1")}{" "}
              <span
                onClick={() => navigate("/privacy-policy")}
                className={styles.checkboxLabelHighlight}
              >
                {t("auth.createAccount.form.privacyPolicy")}
              </span>{" "}
              {t("auth.createAccount.form.privacyText2")}
            </Typography>
          }
        />
        <ErrorMessage error={form.acceptPrivacy.error} />

        <FormControlLabel
          control={
            <Checkbox
              name="acceptTerms"
              checked={form.acceptTerms.value}
              value={form.acceptTerms.value}
              onChange={handleFormFieldChange}
            />
          }
          label={
            <Typography className={styles.checkboxLabel}>
              {t("auth.createAccount.form.termsText1")}{" "}
              <span
                onClick={() => navigate("/terms-of-use")}
                className={styles.checkboxLabelHighlight}
              >
                {t("auth.createAccount.form.termsOfUse")}
              </span>
            </Typography>
          }
        />
        <ErrorMessage error={form.acceptTerms.error} />
        <div className={styles.actionContainer}>
          <Button
            onClick={handleSubmit}
            className={styles.button}
            variant="contained"
            disabled={continueDisabled}
            color="primary"
          >
            {loading ? t("auth.loading") : t("auth.continue")}
          </Button>
        </div>
      </PageContent>
    </Page>
  );
};

export default CreateAccount;
