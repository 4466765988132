import React, { useEffect, useTransition } from "react";
import { useInjectReducer } from "redux-injectors";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import rtl from "jss-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@mui/styles";
import MuiTheme from "./mui";
import {
  reducer,
  selectDirection,
  themeSliceKey,
  toggleDirection,
} from "./slice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
export const ThemeProvider = (props) => {
  const { i18n } = useTranslation();
  useInjectReducer({ key: themeSliceKey, reducer: reducer });
  const searchParams = new URL(window.location.href).searchParams;
  const language = searchParams.get("hl");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleDirection(i18n.dir(i18n.language)));
    if (language) {
      i18n.changeLanguage(language);
    }
    return () => {};
  }, [language]);
  const themeDirection = useSelector(selectDirection);
  console.log({ themeDirection });

  return (
    <StylesProvider jss={jss}>
      <CacheProvider value={cacheRtl}>
        <MuiThemeProvider
          theme={{ ...MuiTheme, direction: i18n.dir(i18n.language) || "ltr" }}
        >
          {props.children}
        </MuiThemeProvider>
      </CacheProvider>
    </StylesProvider>
  );
};
