import { LoginResponse } from "types/LoginResponse";
import { createSlice } from "utils/@reduxjs/toolkit";

import { createAction, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import {
  Auth,
  ContainerState,
  initialAuthState,
  LoginErrorType,
} from "./types";
import { FormError } from "../types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container

const formSlice = createSlice({
  name: "authState",
  initialState: initialAuthState,
  reducers: {
    setForm: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, "");
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setType: (state, action: PayloadAction<any>) => {
      state.type = action.payload;
    },
    setLoginForm: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      set(state.loginForm, `${name}.value`, value);
      set(state.loginForm, `${name}.error`, "");
    },
    setLoginFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      // const { key } = action.payload;
      action.payload.errors.forEach((error) => {
        set(state.loginForm, `${error.name}.error`, error.error);
      });
      // console.log(state.loginForm);
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      // const { key } = action.payload;
      action.payload.errors.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
      // console.log(state.loginForm);
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetModule: (state) => {
      state.error = initialAuthState.error;
      state.form = initialAuthState.form;
      state.loading = initialAuthState.loading;
      state.otp = initialAuthState.otp;
      state.token = initialAuthState.token;
    },
    setOtp: (state, action) => {
      state.otp.value = action.payload;
      // state.error = "";
    },
    logout(state) { },
    login(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    createAccountRequest: (state, action) => {
      state.loading = true;
    },
    forgotPassword(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.form = initialAuthState.form;
      state.loading = false;
      state.error = null;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setError(state, action) {
      state.error = action.payload.message;
      state.invalidAttempts.attempts = action.payload.attempts
      state.invalidAttempts.duration = action.payload.duration
      state.loading = false;
    },
    createAccountSuccess: (state, action) => {
      state.loading = false;
    },
    verifyOtpRequest: (state, action) => {
      state.loading = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = formSlice;
