import { Typography } from "@mui/material";
import React from "react";

type Props = {
  error: any;
};

const ErrorMessage = (props: Props) => {
  return (
    props.error && (
      <Typography sx={{ fontSize: "12px", color: "#F00" }}>
        {props.error}
      </Typography>
    )
  );
};
export default ErrorMessage;
