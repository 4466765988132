import React from "react";

import MuiTextField from "@mui/material/TextField";

import { useStyles } from "../TextField/styles";
import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TextFieldWithLabel = ({
  width = 0 || "",
  className = "",
  label = "",
  containerClass = "",
  mb = 0,
  field = "",
  dir,
  ...props
}: any) => {
  const classes = useStyles({ width });
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", mb }}
      className={containerClass}
    >
      <Typography
        className={props.labelStyle ? classes.alignCenter : classes.label}
      >
        {label}
      </Typography>
      {field ? (
        field
      ) : (
        <MuiTextField
          variant="outlined"
          margin="normal"
          dir={dir ?? "ltr"}
          className={clsx(["TextInput", classes.TextInput, className])}
          {...props}
        />
      )}
    </Box>
  );
};

export default TextFieldWithLabel;
