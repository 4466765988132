/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialAuthState as initialState } from "./types";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState;

export const selectAuth = createSelector([selectDomain], (authState) => authState);
export const selectCreateAccountForm = createSelector(
  [selectDomain],
  (authState) => authState.form
);

export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginForm
);
export const selectType = createSelector([selectDomain], (state) => state.type);

export const selectToken = createSelector(
  [selectDomain],
  (state) => state.token
);

export const selectLoading = createSelector(
  [selectDomain],
  (authState) => authState.loading
);

export const selectOtp = createSelector([selectDomain], (state) => state.otp);

export const selectError = createSelector(
  [selectDomain],
  (authState) => authState.error
);

export const selectErrorMessage = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectInvalidAttempts = createSelector(
  [selectDomain],
  (state) => state.invalidAttempts
);
