import { Box, Typography } from "@mui/material";
import React from "react";
import { APP_VERSION } from "utils/constants";

import LogoImage from "../../assets/ShopiTV-logo.png";
import { useStyles } from "./styles";
type Props = {
  heading?: JSX.Element;
  subHeading?: JSX.Element;
};

const PageImageHeader = (props: Props) => {
  const styles = useStyles();
  const version = (
    <>
      App Version
      <br />
      {APP_VERSION}
    </>
  );
  return (
    <Box className={styles.headerContainer}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "30px 12px 20px 12px",
        }}
      >
        <Typography className={styles.heading}>{props.heading}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={LogoImage}
            style={{ height: "48px" }}
            alt="shop-it-tv_logo"
          />
          <Typography
            sx={{
              textAlign: "center",
              color: "#FFFFFF90",
              fontSize: 10,
              mt: 1,
            }}
          >
            {version}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: "12px", pb: "10px" }}>
        <Typography className={styles.subHeading}>
          {props.subHeading}
        </Typography>
      </Box>
    </Box>
  );
};

export default PageImageHeader;
