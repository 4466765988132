import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  button: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 800,
    padding: "12px 0",
    width: "85%",
    borderRadius: "10px",
    marginBottom: '100px  '
  },
  actionContainer: {
    textAlign: "center",
  },
  checkboxLabel: { fontSize: "12px", color: "#FFF" },
  checkboxLabelHighlight: { color: "#FAA61A", textDecoration: "underline" },
});
