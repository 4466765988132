export const APP_API_URL =
  process.env.REACT_APP_API_URL || 'https://dev.shopitv.zangula.net/api';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV !== 'production';
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
export const API_URL = APP_API_URL;
export const LOGIN_HEADER_HEIGHT = 96;
export const DASHBOARD_MENU_WIDTH = 217;
export const DASHBOARD_TOP_BAR_HEIGHT = 116;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;
export const DEFAULT_PAGE_LIMIT = 10;
export const APP_ENV = process.env.REACT_APP_API_ENV || 'LOCAL';
console.log({API_URL, APP_ENV})
export const statusFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'TRUE' },
  { label: 'In-active', value: 'FALSE' },
];

export const ctaOptions = [
  { key: 'APPLY_NOW', value: "Apply Now" },
  { key: 'BOOK_NOW', value: "Book Now" },
  { key: 'BUY_TICKETS', value: "Buy Tickets" },
  { key: 'CALL_NOW', value: "Call Now" },
  { key: 'CONTACT_US', value: "Contact Us" },
  { key: 'DONATE_NOW', value: "Donate Now" },
  { key: 'DOWNLOAD', value: "Download" },
  { key: 'GET_OFFER', value: "Get Offer" },
  { key: 'GET_QUOTE', value: "Get Quote" },
  { key: 'GET_SHOWTIME', value: "Get Showtimes" },
  { key: 'INSTALL_NOW', value: "Install Now" },
  { key: 'LEARN_MORE', value: "Learn more" },
  { key: 'WISHLIST', value: "Wishlist" },
  { key: 'LISTEN_NOW', value: "Listen Now" },
  { key: 'OPEN_LINK', value: "Open Link" },
  { key: 'ORDER_NOW', value: "Order Now" },
  { key: 'PLAY_GAME', value: "Play Game" },
  { key: 'REQUEST_TIME', value: "Request Time" },
  { key: 'SEE_MENU', value: "See Menu" },
  { key: 'SEND_MESSAGE', value: "Send Message" },
  { key: 'SEND_WHATSAPP_MSG', value: "Send WhatsApp Message" },
  { key: 'SHOP_NOW', value: "Shop Now" },
  { key: 'SIGN_UP', value: "Sign Up" },
  { key: 'SUBSCRIBE', value: "Subscribe" },
  { key: 'USE_APP', value: "Use App" },
  { key: 'VIEW_EVENT', value: "View Event" },
  { key: 'SHOW_MORE', value: "Show More" },
  { key: 'FOLLOW', value: "Follow" },
];
