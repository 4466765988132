import { FormError } from "app/containers/types";
import { CreateAccountForm } from "../types";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { emailValidator } from "utils/helpers";

export function valdiator(form: CreateAccountForm): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.firstName.value) {
    errors.push({
      name: "firstName",
      error: "First name is required",
    });
  }
  if (!form.lastName.value) {
    errors.push({
      name: "lastName",
      error: "Last name is required",
    });
  }
  if (!form.dateOfBirth.value) {
    errors.push({
      name: "dateOfBirth",
      error: "auth.invalid.datereq",
    });
  }
  const todayDate = new Date();
  const minDate = todayDate.getFullYear() - 8;
  const setDate = new Date(new Date().setFullYear(minDate));
  if (
    form.dateOfBirth.value &&
    form.dateOfBirth.value.getFullYear() > setDate.getFullYear()
  ) {
    errors.push({
      name: "dateOfBirth",
      error: "auth.invalid.date",
    });
  }
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: "Email is required",
    });
  }
  if (!form.gender.value) {
    errors.push({
      name: "gender",
      error: "Gender is required",
    });
  }

  if (form.email.value && !emailValidator(form.email.value)) {
    errors.push({
      name: "email",
      error: "auth.invalid.email",
    });
  }
  if (!form.phone.value) {
    errors.push({
      name: "phone",
      error: "Phone number is required",
    });
  }

  if (form.phone.value) {
    const phoneNumValidation =
      isPossiblePhoneNumber(form.phone.value, "IL") &&
      isValidPhoneNumber(form.phone.value, "IL");
    // isValidPhoneNumber(form.phone.value) ||
    // isValidPhoneNumber(form.phone.value, 'IL');
    console.log(phoneNumValidation, "phoneNumValidation");
    if (!phoneNumValidation) {
      errors.push({
        name: "phone",
        error: "auth.invalid.phone",
      });
    }
  }
  if (!form.acceptPrivacy.value) {
    errors.push({
      name: "acceptPrivacy",
      error: "Before procceding you should agree to our privacy policies",
    });
  }
  if (!form.acceptTerms.value) {
    errors.push({
      name: "acceptTerms",
      error: "Before procceding you should agree to our Terms of service",
    });
  }
  return errors;
}
