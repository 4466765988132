/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { makeStyles } from "@mui/styles";
import { AuthPage } from "app/containers/Auth";
import { userRepoSaga } from "app/containers/Auth/saga";
import { reducer, sliceKey, actions } from "app/containers/Auth/slice";

import { TermsOfUse } from "app/containers/TermsOfUse";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  selectDirection,
  themeActions,
  toggleDirection,
} from "styles/theme/slice";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import CreateAccount from "./containers/Auth/CreateAccount";
import InvalidCode from "./containers/Auth/InvalidOtp";
import Login from "./containers/Auth/Login";
import OtpVerification from "./containers/Auth/OTPVerification";
import ThankYou from "./containers/Auth/Thankyou";
// import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
// import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
// import { IconButton } from '@mui/material';
import PrivacyPolicy from "./containers/PrivacyPolicy";
import { UnsupportedScreen } from "./containers/UnsupportedScreen";
const useStyles = makeStyles({
  appRoot: {
    width: "100%",
    height: "100%",
  },
});
export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });
  const styles = useStyles();
  const { i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();
  const searchParams = new URL(window.location.href).searchParams;
  const language = searchParams.get("hl");
  const token = searchParams.get("authToken");

  useEffect(() => {
    if (token) {
      dispatch(actions.setToken(token));
    }
  }, [token]);

  useEffect(() => {
    dispatch(toggleDirection(i18n.dir(i18n.language)));
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - MyStream"
        defaultTitle="MyStream"
      >
        <meta name="description" content="A MyStream application" />
      </Helmet>
      <div className={styles.appRoot} dir={direction}>
        {width && width <= 850 ? (
          <Routes>
            <Route path="/:macAddress/*" element={<AuthPage />}>
              <Route path="otp" element={<OtpVerification />} />
              <Route path="auth" element={<Login />} />
              <Route index element={<CreateAccount />} />
            </Route>
            <Route path="/invalid" element={<InvalidCode />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Navigate to="/create-account" />} />
          </Routes>
        ) : (
          <UnsupportedScreen />
        )}
      </div>
    </BrowserRouter>
  );
}
