import { colors, createTheme } from "@mui/material";

const theme = createTheme({
  direction: "ltr",

  palette: {
    mode: "dark",
    background: {
      default: "#242424",
      paper: "#2A2D35",
    },
    primary: {
      main: "#faa619",
    },
    secondary: {
      main: "#3C4858",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF90",
    },
  },
  typography: {
    // fontFamily:'Source Sans 3 sans-serif !important',
    allVariants: {
      textTransform: "none",
      fontFamily: `"Roboto", "Helvetica", "Arial", "Poppins", sans-serif `,
    },
  },
});

export default theme;
