import React, { useEffect, useState } from "react";
import "./style.css";
import qs from "query-string";
import Page from "app/components/Page";
import PageHeading from "app/components/PageHeading";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import PageContent from "app/components/PageContent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
type Props = {};

const PrivacyPolicy = (props: Props) => {
  const [hl, setLang] = useState("en");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const url = qs.parseUrl(window.location.href);
    const isTV = url?.query?.tv == "true";
    const hl = url?.query?.hl as string;
    setLang(hl);
    if (isTV) {
      document.body.classList.add("tv-page");
    }
    return function cleanup() {
      document.body.classList.remove("tv-page");
    };
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const hebrewPrivacy = `עובדה ידועה זה מכבר שדעתו של הקורא מוסחת על ידי התוכן הקריא של דף כאשר מסתכלים על הפריסה שלו. הטעם בשימוש ב-Lorem Ipsum הוא שיש לו הפצה נורמלית פחות או יותר של אותיות, בניגוד לשימוש ב'תוכן כאן, תוכן כאן', מה שגורם לזה להיראות כמו אנגלית קריא. חבילות רבות לפרסום שולחני ועורכי דפי אינטרנט משתמשים כעת ב-Lorem Ipsum כטקסט מודל ברירת המחדל שלהם, וחיפוש אחר 'lorem ipsum' יחשוף אתרי אינטרנט רבים שעדיין בחיתולים. גרסאות שונות התפתחו במהלך השנים, לפעמים במקרה, לפעמים בכוונה (הומור מוזרק וכדומה).
קיימות וריאציות רבות של קטעים של Lorem Ipsum זמינות, אך הרוב סבלו משינויים בצורה כלשהי, על ידי הומור מוזרק, או מילים אקראיות שאינן נראות אמינות אפילו מעט. אם אתה מתכוון להשתמש בקטע של Lorem Ipsum, אתה צריך להיות בטוח שאין שום דבר מביך מוסתר באמצע הטקסט. כל מחוללי Lorem Ipsum באינטרנט נוטים לחזור על נתחים מוגדרים מראש לפי הצורך, מה שהופך את זה למחולל האמיתי הראשון באינטרנט. הוא משתמש במילון של למעלה מ-200 מילים בלטיניות, בשילוב עם קומץ של מבני משפטים לדוגמה, כדי ליצור לורם איפסום שנראה סביר. לכן, הלורם איפסום שנוצר תמיד נקי מחזרות, הומור מוזרק, או מילים לא אופייניות וכו'.
                    `;
  const englishPrivacy = `It is a long established fact that a reader will be distracted by
the readable content of a page when looking at its layout. The point
of using Lorem Ipsum is that it has a more-or-less normal
distribution of letters, as opposed to using 'Content here, content
here', making it look like readable English. Many desktop publishing
packages and web page editors now use Lorem Ipsum as their default
model text, and a search for 'lorem ipsum' will uncover many web
sites still in their infancy. Various versions have evolved over the
years, sometimes by accident, sometimes on purpose (injected humour
and the like). There are many variations of passages of Lorem Ipsum
available, but the majority have suffered alteration in some form,
by injected humour, or randomised words which don't look even
slightly believable. If you are going to use a passage of Lorem
Ipsum, you need to be sure there isn't anything embarrassing hidden
in the middle of text. All the Lorem Ipsum generators on the
Internet tend to repeat predefined chunks as necessary, making this
the first true generator on the Internet. It uses a dictionary of
over 200 Latin words, combined with a handful of model sentence
structures, to generate Lorem Ipsum which looks reasonable. The
generated Lorem Ipsum is therefore always free from repetition,
injected humour, or non-characteristic words etc.`;

  return (
    <>
      <Page>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#FFF",
          }}
        >
          <Button
            startIcon={<ChevronLeft />}
            sx={{
              color: "#FFF",
              "& .MuiButton-startIcon": {
                transform:
                  i18n.language === "he" ? "rotate(180deg)" : "rotate(0deg)",
              },
            }}
            onClick={goBack}
          >
            {t("auth.back")}
          </Button>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton>
              <ChevronLeft />
            </IconButton>
            <Typography sx={{ fontSize: "12px" }}>Back</Typography>
          </Box> */}
          <Typography
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            {t("TermsAndPrivacy.privacy")}
          </Typography>
        </Box>
        <PageContent px={1.5}>
          <Typography sx={{ fontSize: 28, color: "#FAA61A", fontWeight: 800 }}>
            {t("TermsAndPrivacy.privacyShopi")}
          </Typography>
          <Typography mt={2} color="#FFF">
            {i18n.language === "he" ? hebrewPrivacy : englishPrivacy}
          </Typography>
        </PageContent>
      </Page>
    </>
  );
};

export default PrivacyPolicy;
