import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  onEnd?(): void;
  reset: boolean;
  setTimerEnded?(val: boolean): void;
};

const TIMER_VALUE = 45;
const Timer = (props: Props) => {
  const ref = useRef<any>(null);
  const [timeLeft, setTimeLeft] = useState(TIMER_VALUE);
  useEffect(() => {
    if (timeLeft === 0) {
      // @ts-ignore
      props?.setTimerEnded(true);
    }
    if (props.reset) {
      setTimeLeft(TIMER_VALUE);
      // @ts-ignore
      props?.setTimerEnded(false);
    }

    if (timeLeft == 0) {
      if (props.onEnd) {
        props.onEnd();
      }
      return;
    }
    clearInterval(ref.current);
    ref.current = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(ref.current);
  }, [timeLeft, props.reset]);
  const result = new Date(timeLeft * 1000).toISOString().slice(14, 19);
  return (
    <>
      <Typography
        style={{
          fontSize: "21px",
          fontWeight: 700,
          lineHeight: "20px",
          color: "#FAA61A",
        }}
      >
        {result}
      </Typography>
    </>
  );
};
export default Timer;
