import React from "react";

import { Box } from "@mui/material";

const Page = (props) => {
  return (
    <Box
      mx={"auto"}
      bgcolor={"#242424"}
      className={props?.className}
      minHeight="100vh"
    >
      <Box position={"relative"}>{props?.children}</Box>
    </Box>
  );
};

export default Page;
