import { FormElement } from "../types";
export type CreateAccountForm = {
  firstName: FormElement;
  lastName: FormElement;
  dateOfBirth: FormElement;
  email: FormElement;
  phone: FormElement;
  gender: FormElement;
  acceptPrivacy: FormElement;
  acceptTerms: FormElement;
};
export type LoginForm = {
  phone: FormElement;
  code: FormElement;
};
/* --- STATE --- */

export type Auth = {
  form: CreateAccountForm;
  otp: FormElement;
  error: string | null;
  loading: boolean;
  token: string;
  loginForm: LoginForm;
  type: string,
  invalidAttempts: {
    attempts: number|string,
    duration: string,
  },
  
};
export const initialAuthState: Auth = {
  form: {
    firstName: { value: "" },
    lastName: { value: "" },
    dateOfBirth: { value: '' },
    email: { value: "" },
    phone: { value: "" },
    gender: { value: "" },
    acceptPrivacy: { value: false },
    acceptTerms: { value: false },
  },
  loginForm: {
    code: { value: "" },
    phone: { value: "" },
  },
  otp: { value: "" },
  error: "",
  invalidAttempts:{
    attempts:0,
    duration:'',
  },
  loading: false,
  token: "",
  type: "",
};

export enum LoginErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = Auth;
