import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";
import React from "react";
interface props extends BoxProps {
  pt?: number | string;
  children?: any;
  className?: string;
  px?: number | string;
}
const PageContent = ({
  pt = 0,
  px = 0,
  children,
  className = "",
  ...props
}: props) => {
  return (
    <Box sx={{ pt, px, overflowY: "scroll" }} className={className} {...props}>
      {children}
    </Box>
  );
};

export default PageContent;
