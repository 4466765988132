import { FormError } from "app/containers/types";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LoginForm } from "../types";

export const validator = (form: LoginForm): Array<FormError> => {
  const errors: Array<FormError> = [];
  // if (!form.code.value) {
  //   errors.push({
  //     name: "code",
  //     error: "Code is required",
  //   });
  // }
  if (!form.phone.value) {
    errors.push({
      name: "phone",
      error: "Phone is required",
    });
  }
  const phoneNumValidation =
    isValidPhoneNumber(form.phone.value) ||
    isValidPhoneNumber(form.phone.value, 'IL');
  if (form.phone.value && !phoneNumValidation) {
    errors.push({
      name: "phone",
      error: "auth.invalid.phone",
    });
  }
  return errors;
};
